import {log, sendError} from './../../utilities/measure.js';
import {DP} from './../GameMaker/keys.js';
import {roundTo} from './../GameMaker/display.js';

export const getFraction = (a, b) => {
    
    let f =`\\frac{${a}}{${b}}`;
    return f;
}


export const getMultiple = (multiples, b) => {
    let ray = multiples;
    if(!Array.isArray(multiples)){
        ray = [multiples, b]; //lazy to update all fun from the old sig
    }
    let m = ray.reduce((agg, e, i) => {
        return `${agg}${i===0?``:` \\cdot `}${e}`;
    })
    return m;
}
