/**
 * 
DRIP
 stockStrength:50 mg
stockVolume:1000 ml

patientStrength:300 mcg
dripRate:60 ml per hour

BSA
stockStrength:300 mg
stockVolume:4 ml

patientStrength:300 mcg
bsa:0.9

PILL
stockStrength:100 mg
stockVolume:1 pill

patientStrength:100 mg

export const EXAMPLES = {
    dose: [
        {id: 'convert', title: '1. Convert', text: 'Convert patients medication unit to the stock medication unit to get the required medication, eg 0.5mg to 500mcg'},
        {id: 'stockStrength', title: '2. Get Units', text: 'Divide the required medication by the stock strength to determine how many units of medication will be required, eg 500mcg/100mcg = 5 units'},
        {id: 'stockVolume', title: '3. Get Volume',text: `Multiply the required stock units by the volume of stock the medication is contained in, eg 5 units of 3ml injections requires 15ml worth of stock medication`},
        {id: 'checkAnswers', title: '4. Check Answer',text: `Scan the qr codes on the back of the patient and the stock card to visit the medication math site and calculate the answer`},
    ],
    drip: [
        {id: 'convert', title: '1. Convert', text: 'Convert patients medication unit to the stock medication unit to get the required medication, eg 0.5mg to 500mcg'},
        {id: 'stockStrength',title: '2. Get Units', text: 'Divide the required medication by the stock strength to determine how many units of medication will be required, eg 500mcg/100mcg = 5 units'},
        {id: 'stockVolume', title: '3. Get Volume', text: `Multiply the required stock units by the volume of stock the medication is contained in, eg 5 units of 3ml injections requires 15ml worth of stock medication`},
        {id: 'dripRate', title: '4. Get the drip rate', text: `Get the drip rate in terms of the stock volume delivered per hour, eg 60 ml per hour`},
        {id: 'time',  title: '4. Get the delivery time', text: `Calculate the time by dividing the stock volume required by the calculated drip rate`},
        {id: 'checkAnswers', title: '5. Check Answer',text: `Scan the qr codes on the back of the patient and the stock card to visit the medication math site and calculate the answer`},
    ],
    bsa: [
        {id: 'convert', title: '1. Convert', text: 'Convert patients recommended dose unit to the unit used by the stock medication, eg 0.5mg to 500mcg'},
        {id: 'allowedStrength', title: '2. Get Body Surface Area Dose', text: 'Multiply the recommended dose by the patients body surface area to get their maximum dose, eg 500mcg*0.8mcg/m^2 = 400mcg'},
        {id: 'stockVolume', title: '3. Get Volume',text: `Multiply the required stock units by the volume of stock the medication is contained in, eg 5 units of 3ml injections requires 15ml worth of stock medication`},
        {id: 'checkAnswers', title: '4. Check Answer',text: `Scan the qr codes on the back of the patient and the stock card to visit the medication math site and calculate the answer`},
    ]
}
 */
import React from 'react';
import {getFraction, getMultiple} from '../Math/utilities';
import MathView from '../Math/index';
import {calculateAnswer, pluralize} from './utilities';
import {log} from './../../utilities/measure.js';
import {DP} from './keys.js';

export function roundTo(value, decimals, rounder = Math.round) {
    return Number(rounder(value+'e'+decimals)+'e-'+decimals) || rounder(value);
}


export const DUMMY_STOCK_DOSE =  {
    type:'stock',  
    strength: 150, 
    drug: 'morphine', 
    vector: 'ml', 
    question: 'dose', 
    vectorVolume: 1, 
    unit: 'mg',
    text: `150mg of morphine per 1ml`
};
DUMMY_STOCK_DOSE.properties = {
    stockStrength: `${DUMMY_STOCK_DOSE.strength} ${DUMMY_STOCK_DOSE.unit}`,
    stockVolume: `${DUMMY_STOCK_DOSE.vectorVolume} ${DUMMY_STOCK_DOSE.vector}`
}
export const DUMMY_STOCK_DRIP =  {
    type:'stock',  
    unit: 'mcg', 
    strength: 50, 
    drug: 'morphine', 
    vector: 'drip', 
    question: 'drip', 
    vectorVolume: 1000, 
    vectorVolumeUnit: 'ml',
    text: `50mcg of morphine per 1000ml of stock`
};
DUMMY_STOCK_DRIP.properties = {
    stockStrength: `${DUMMY_STOCK_DRIP.strength} ${DUMMY_STOCK_DRIP.unit}`,
    stockVolume: `${DUMMY_STOCK_DRIP.vectorVolume} ${DUMMY_STOCK_DRIP.vectorVolumeUnit}`
}
export const DUMMY_STOCK_BSA =  {
    type:'stock',  
    strength: 150, 
    drug: 'morphine', 
    vector: 'pill', 
    vectorVolume: 1, 
    unit: 'mg',
    question: 'bsa',
    text: `150mg of morphine per tablet`
};
DUMMY_STOCK_BSA.properties = {
    stockStrength: `${DUMMY_STOCK_BSA.strength} ${DUMMY_STOCK_BSA.unit}`,
    stockVolume: `${DUMMY_STOCK_BSA.vectorVolume} ${DUMMY_STOCK_BSA.vector}`
}
export const DUMMY_QUESTION_DOSE = {
    type: 'question', 
    question: 'dose', 
    name: 'Denton', 
    drug: 'morphine', 
    recommendedDose: 600, 
    recommendedDoseUnit: 'mg',
    text: `Denton needs 600mg of morphine`
};
DUMMY_QUESTION_DOSE.properties = {
    patientStrength: `${DUMMY_QUESTION_DOSE.recommendedDose} ${DUMMY_QUESTION_DOSE.recommendedDoseUnit}`
}
export const DUMMY_QUESTION_DRIP = {
    type: 'question', 
    question: 'drip', 
    name: 'Denton', 
    drug: 'morphine', 
    recommendedDose: 600, 
    recommendedDoseUnit: 'mg',
    dripRate: 60, //drip rate per hour
    dripUnit: 'ml',
    dripTime: 'hour',
    text: `Denton needs 600mg of morphine via intravenous drip`
};
DUMMY_QUESTION_DRIP.properties = {
    patientStrength: `${DUMMY_QUESTION_DRIP.recommendedDose} ${DUMMY_QUESTION_DRIP.recommendedDoseUnit}`,
    dripRate: `${DUMMY_QUESTION_DRIP.dripRate} ${DUMMY_QUESTION_DRIP.dripUnit} per ${DUMMY_QUESTION_DRIP.dripTime}`
}
export const DUMMY_QUESTION_BSA = {
    type: 'question', 
    question: 'bsa', 
    name: 'Denton', 
    bsa: 1.3, 
    drug: 'morphine', 
    recommendedDose: 200, 
    recommendedDoseUnit: 'mg',
    text: `Denton has a body surface area of 1.3 m^2, and requires morphine, which has a recommended dose of 200mg per m^2`
};
DUMMY_QUESTION_BSA.properties = {
    patientStrength: `${DUMMY_QUESTION_BSA.recommendedDose} ${DUMMY_QUESTION_BSA.recommendedDoseUnit}`,
    bsa: DUMMY_QUESTION_BSA.bsa,
}

export const QUESTIONS = [
    {id: 'dose', text: 'Pill and Injection Dosage'},
    {id: 'drip', text: 'Intravenous Fluid'},
    {id: 'bsa', text:  'Body Surface Area'}
]

const getConversionDescription = (fromUnit, toUnit, value) => {
    if(fromUnit === toUnit){
        return [
            <p>The units are the same, {fromUnit}, so the value remains the same, 
                <span className="text-important">{value} {toUnit}</span>
            </p>
        ];
    }else{
        switch(fromUnit){
            case 'mg':
                if(toUnit === 'mcg'){
                    // r = value*1000;
                    return [
                        <p key={`cd-p-${fromUnit}-${toUnit}-${value}`}>the value {value} {fromUnit} is converted to {toUnit} by multiplying {value} by 1000, giving {value*1000} {toUnit}</p>,
                        <MathView key={`cd-m-${fromUnit}-${toUnit}-${value}`} latex={`${getMultiple(value, 1000)}=${value*1000}${toUnit}`} />
                    ]
                }
            case 'mcg':
                if(toUnit === 'mg') {
                    return [
                        <p key={`cd-p-${fromUnit}-${toUnit}-${value}`}>the value {value} {fromUnit} is converted to {toUnit} by dividing {value} by 1000, giving {value/1000} {toUnit}</p>,
                        <MathView key={`cd-m-${fromUnit}-${toUnit}-${value}`} latex={`${getFraction(value, 1000)}=${value/1000}${toUnit}`} />
                    ]
                }
        }
    }
    return [
        <p>Invalid units from: {fromUnit} to: {toUnit} </p>
    ]
}
const getBsaExample = (question, stock) => {
    /**
        try{
            result.amountRequired = convertUnit(question.recommendedDoseUnit, stock.unit, roundTo(question.recommendedDose, DP));
        }catch(e){
            result.error = `unit mismatch question: ${question.recommendedDoseUnit}, stock: ${stock.unit}`
        }
        roundTo(result.bsaAmount, DP) = result.amountRequired*question.bsa;
        roundTo(result.dose, DP) = roundTo(result.bsaAmount, DP)/roundTo(stock.strength, DP);
        result.quantity = roundTo(result.dose, DP)*stock.vectorVolume;
        result.unit = pluralize(stock.vector, result.quantity);
     */
    let result = calculateAnswer(question, stock);
    log(`getExample getPillExample`, result);
    return [
        <h3 key="h3-1">Working:</h3>,
        stock.vector === 'ml'?<p key="p-1">The question is 'how many milliliters of the stock drug does the patient need injected given their body surface area limit?'</p>:
        <p key="p-2">The question is, 'how many pills of the stock drug does the patient need given their body surface area limit?</p>,
        <p key="p-3">The stock of {stock.drug} contains <span className="text-important">{roundTo(stock.strength, DP)} {stock.unit} per {stock.vectorVolume} {pluralize(stock.vector, stock.vectorVolume)}</span></p>,
        <p key="p-4">The patient has been prescribed {roundTo(question.recommendedDose, DP)} {question.recommendedDoseUnit}, this needs to be converted to the stock unit.</p>,
        ...getConversionDescription(question.recommendedDoseUnit, stock.unit, roundTo(question.recommendedDose, DP)),
        <p key="p-5">The amount the patient can take depends on their body surface area, {question.bsa},
         multiply the recommended dose by the patients body surface area: </p>,
         <MathView key="m-1" block latex={`${getMultiple('amountRequired', 'bodySurfaceArea')}=amountRequired`} />,
         <MathView key="m-2" block latex={`${getMultiple(result.amountRequired, question.bsa)}=${roundTo(result.bsaAmount, DP)} ${stock.unit}`} />,
         <p key="p-6"><span className="text-important">amount required = {roundTo(result.amountRequired, DP)}</span></p>,
        <p key="p-7">Then divide the amount permitted by the stock strength to get the number of doses permitted:</p>, 
        <MathView key="m-3" block latex={`${getFraction(`amountRequired`, `stockStrength`)} = dose`} />,
        <MathView key="m-4" block latex={`${getFraction(result.amountRequired, roundTo(stock.strength, DP))} = ${roundTo(result.dose, DP)}`} />,
        <p key="p-8">Then multiply the doses, {roundTo(result.dose, DP)}, by the stock volume to get the total amount of stock required to 
            get the prescribed dose:</p>,
        <MathView key="m-5" block latex={`${getMultiple('dose', 'stockVolume')} = amountOfMedicationRequired`} />,
        <MathView key="m-6" block latex={`${getMultiple(roundTo(result.dose, DP), stock.vectorVolume)} = ${roundTo(result.dose, DP)*stock.vectorVolume}${pluralize(stock.vector, roundTo(result.dose, DP)*stock.vectorVolume)}`} /> 
        
    ]
}
const getPillExample = (question, stock) => {
    /**
     *         let recommendedDoseUnit = question.recommendedDoseUnit;
        let recommendedDose = question.recommendedDose;
        let amountRequired;
        try{
            amountRequired = convertUnit(recommendedDoseUnit, stock.unit, recommendedDose);
        }catch(e){
            result.error = `unit mismatch question: ${recommendedDoseUnit}, stock: ${stock.unit}`
        }
        result.quantity = (amountRequired/roundTo(stock.strength, DP))*stock.vectorVolume;
        result.unit = pluralize(stock.vector, result.quantity);
        result.text = `${question.name} requires ${amountRequired} ${stock.unit} of ${stock.drug}, 
        which is ${result.quantity} ${result.unit} of the stock ${stock.drug}`;
     */
    let result = calculateAnswer(question, stock);
    log(`getExample getPillExample`, result);
    return [
        <h3 key="h3-1">Working:</h3>,
        stock.vector === 'ml'?<p key="p-1">The question is 'how many millilitres of the stock drug does the patient need injected to get their prescribed dose?'</p>:
        <p key="p-2">The question is, 'how many pills of the stock drug does the patient need to get their prescribed dose?</p>,
        <p key="p-3">The stock of {stock.drug} contains <span className="text-important">{roundTo(stock.strength, DP)} {stock.unit} per {stock.vectorVolume} {stock.vector}</span></p>,
        <p key="p-4">The patient has been prescribed {roundTo(question.recommendedDose, DP)} {question.recommendedDoseUnit}: </p>,
        <MathView key="m-1" latex={`prescription = ${roundTo(question.recommendedDose, DP)} ${question.recommendedDoseUnit}`} />,
        <p key="p-5">this needs to be converted to the stock unit.</p>,
        ...getConversionDescription(question.recommendedDoseUnit, stock.unit, roundTo(question.recommendedDose, DP)),
        <p key="p-6"><span  className="text-important">amount required = {roundTo(result.amountRequired, DP)} {stock.unit}</span></p>,
        <p key="p-7">Then divide the amount required by the stock strength to get the number of doses required: </p>,
        <MathView key="m-2" block latex={`${getFraction('amountRequired', 'stockStrength')} = dose`} />,
        <MathView key="m-3" block latex={`${getFraction(result.amountRequired, roundTo(stock.strength, DP))} = ${roundTo(result.dose, DP)}`} />,
        <p key="p-8">Then multiply the doses, {roundTo(result.dose, DP)}, by the stock volume to get the total amount of stock required to 
            get the prescribed dose:</p>,
        <MathView key="m-4" latex={`${getMultiple(roundTo(result.dose, DP), stock.vectorVolume)} = ${roundTo(result.dose, DP)*stock.vectorVolume}`} />,
        <p key="p-9">{pluralize(stock.vector, roundTo(result.dose, DP)*stock.vectorVolume)}</p>
    ]
}

/*
calculateAnswer = (question, stock

 roundTo(result.patientRecommendedDoseConverted, DP) = convertUnit(question.recommendedDoseUnit, stock.unit, roundTo(question.recommendedDose, DP));
        roundTo(result.volume, DP) = (roundTo(result.patientRecommendedDoseConverted, DP)/roundTo(stock.strength, DP))*stock.vectorVolume;
        result.dripRate = convertUnit(question.dripUnit, stock.vectorVolumeUnit, roundTo(question.recommendedDose, DP));
        roundTo(result.time, DP) = roundTo(result.volume, DP)/result.dripRate;
        log(`calculateAnswer drip result patientrecommendedDoseConverted ${roundTo(result.patientRecommendedDoseConverted, DP)} 
                    volume ${roundTo(result.volume, DP)} dripRate ${result.dripRate} time ${roundTo(result.time, DP)}`);
        result.quantity = roundTo(result.time, DP);
        result.unit = 'hours';
        result.text = `${question.name} requires ${roundTo(result.patientRecommendedDoseConverted, DP)} ${stock.unit} of ${stock.drug}.
        With a stock concentration of ${roundTo(stock.strength, DP)} ${stock.unit} of ${stock.drug} per ${stock.vectorVolume} ${stock.vectorVolumeUnit}, that'll require ${roundTo(result.volume, DP)} ${stock.vectorVolumeUnit} of stock.
        At a drip rate of ${result.dripRate} ${stock.vectorVolumeUnit}, that'll take ${roundTo(result.time, DP)} ${result.unit} to deliver.`;
*/
const getDripExample = (question, stock) => {
    let result = calculateAnswer(question, stock);
    log(`getExample getDripExample`, result);
    return [
        <h3 key="working">Working:</h3>,
        <p  key="p-2">The question is, how many hours will it take to give the patient all their medication?</p>,
        <p key="p-3">The patients prescribed dose is {roundTo(question.recommendedDose, DP)} {question.recommendedDoseUnit} of {question.drug}, while 
            the stock {stock.drug} contains {roundTo(stock.strength, DP)} {stock.unit} per {stock.vectorVolume} {pluralize(stock.vectorVolumeUnit, stock.vectorVolume)}.
        </p>,
        <p key="p-4">Convert the patients recommended dose unit to match the stock dose unit:</p>,
         ...getConversionDescription(question.recommendedDoseUnit, stock.unit, roundTo(question.recommendedDose, DP)),
        <p key="p-5"><span className="text-important">amount required = {roundTo(result.patientRecommendedDoseConverted, DP)} {stock.unit}</span></p>,
        <p key="p-6">You'll drip feed this via intravenous infusion to the patient at a rate of {roundTo(question.recommendedDose, DP)} {question.dripUnit}.</p>,
        <p key="p-7">Then calculate how much stock volume is required to deliver this amount of medication,  </p>,
        <MathView key="m-1" block latex={`${getMultiple(
                    getFraction('amountRequired', 'stockStrength'),
                    stock.vectorVolume
                    )} = ${roundTo(result.volume, DP)} ${pluralize(stock.vectorVolumeUnit, roundTo(result.volume, DP))}`} 
        />,
        <MathView key="m-2" block latex={`${getMultiple(
                    getFraction(roundTo(result.patientRecommendedDoseConverted, DP), roundTo(stock.strength, DP)),
                    stock.vectorVolume
                    )} = ${roundTo(result.volume, DP)} ${pluralize(stock.vectorVolumeUnit, roundTo(result.volume, DP))}`} 
        />,
        <MathView key="m-3" block latex={`stockVolume =  ${roundTo(result.volume, DP)} ${pluralize(stock.vectorVolumeUnit, roundTo(result.volume, DP))}`} />,
        <p key="p-8">Convert the intravenous drip rate to the same unit as the stock volume:</p>,
        ...getConversionDescription(question.dripUnit, stock.vectorVolumeUnit, roundTo(question.recommendedDose, DP)),
        <p key="p-9">Calculate the hours to deliver by intravenous infusion:  </p>,
        <MathView key="m-4" block latex={
            `${getFraction(`stockVolume ${stock.vectorVolumeUnit}`, 
            `dripRate ${getFraction(stock.vectorVolumeUnit, 'hour')}`
            )} = ${roundTo(result.time, DP)} ${pluralize('hour', roundTo(result.time, DP))}`
        } />,
        <MathView key="m-5" block latex={
            `${getFraction(`${roundTo(result.volume, DP)} ${stock.vectorVolumeUnit}`, 
                            `${result.dripRate} ${getFraction(stock.vectorVolumeUnit, 'hour')}`
            )} = ${roundTo(result.time, DP)} ${pluralize('hour', roundTo(result.time, DP))}`
        } /> 
    ];
}


export const getExample = (type, question, stock) => {
    if(question && stock){
        try{
            switch(type){
                case 'dose': return getPillExample(question, stock);
                case 'drip': return getDripExample(question, stock);
                case 'bsa':return getBsaExample(question, stock);
                default:
                    return null;
            }
        }catch(e){
            return <div>
                 <h3>Working:</h3>
                <p>Unable to calculate answer with the current inputs</p>
                <p>error: {e.message}</p>
            </div>
        }
    }
    return <div>
         <h3>Working:</h3>
        <p>Both patient and stock are required to calculate a working example</p>
    </div>
}
const getReadableKey = (key) => {
    switch(key){
        case 'stockStrength': return 'Stock Description';
        case 'bsa': return 'Body Surface Area (m^2)';
        case 'patientStrength': return 'Prescribed Dose'
        default: return key;
    }
}
export const renderCard = (title, object) => {
    let views = [<h3 key={`data-title-${title}`} className="capitalize">{title}</h3>];
    if(object){
        if(object.text){
            views.push(<div key={`data-text-${title}`}>
            <p className="inline">{object.text}</p>
        </div>)
        }
        if(object.properties){
            for(let key in object.properties){
                views.push(<div key={`data-${key}-${title}`}>
                    <h4 className="inline">{getReadableKey(key)}: </h4><p className="inline">{object.properties[key]}</p>
                </div>)
            }
        }

    }else{
        views.push(<div key={`no-data-${title}`}>
        <p>No data available.</p>
        <p>Scan a {title} QR code.</p>
    </div>)
    }
    return views;
}


export const renderData = (stock, question) => {
    log(`renderData stock`, stock);
    log(`renderData question`, question);
    return <div className="data-wrapper">
        <div className="card-data answer-card">
            {renderCard('stock', stock)}
        </div>
        <div className="card-data answer-card">
            {renderCard('patient', question)}
        </div>
    </div>

}


export const changeBackPositions = (cards) => {
    //swap 3 for 1, 6 for 4, and 9 for 7
    let nCards = [...cards];
    nCards[0] = cards[2];
    nCards[2] = cards[0];
    nCards[3] = cards[5];
    nCards[5] = cards[3];
    nCards[8] = cards[6];
    nCards[6] = cards[8];
    log(`changeBackPositions cards`, cards);
    log(`changeBackPositions nCards`, nCards);
    return nCards;
}
export const getBackIndex = (index) => {
    switch(index){
        case 0: return 2;
        case 2: return 0;
        case 3: return 5;
        case 5: return 3;
        case 8: return 6;
        case 6: return 8;
    }
    return index;
}
