import * as QRCode from 'easyqrcodejs';
import qs from 'qs';
import {log} from './../../../utilities/measure.js';
/**
var myUrl = "http://www.image.com/?username=unknown&password=unknown";
var encodedURL= "http://www.foobar.com/foo?imageurl=" + encodeURIComponent(myUrl);
 */
const makeParamString = (params) => {
    let str = '';
    for(let key in params){
        str += str?'&':'';
        str += `${key}=${params[key]}`;
    }
    return str;
}
export const makeQRObject = (url, params) => {
   // let args = makeParamString(params);
    let args = qs.stringify(params);
    let address = url + '/?' + encodeURIComponent(args);
    return {
        // ====== Basic
        text: address,
        width: 170,
        height: 170,
        colorDark : "#000000",
        colorLight : "#ffffff",
      //  correctLevel : QRCode.CorrectLevel.H
    }
}

export const getParamsFromUrl = (url) => {
    let decoded = decodeURIComponent(url);
    if(decoded.charAt(0) === '?') decoded = decoded.substring(1);
    let result = qs.parse(decoded);
    log(`URLParameters getParamsFromUrl url ${decoded} result`, result);
    return result;
}

const testGetParamsFromUrl = () => {
    //"?a=5"
    let url = decodeURIComponent(encodeURIComponent('?a=5'));
    getParamsFromUrl(url);
}
testGetParamsFromUrl()
