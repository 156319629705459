import React from 'react';
import PropTypes from 'prop-types';
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    RedditShareButton,
    RedditIcon
  } from 'react-share'
import './styles.css';


/**
 * siteInformation
        title
        description
        author
        keywords
        siteUrl
        lang
        twitterHandle
        hashtags
        socialTitle
        socialDescription
 */
const SIZE = 50;
const ROUND = false;
const ShareView = (props) => {
    let siteInformation = props.siteInformation;
    let {siteUrl, twitterHandle, hashtags, socialTitle, socialDescription} = siteInformation;
    return <div className="social-wrapper">
          <FacebookShareButton url={siteUrl} title={socialTitle} >
                <FacebookIcon  size={SIZE} round={ROUND}/>
         </FacebookShareButton>

          <TwitterShareButton url={siteUrl} title={socialTitle} via={twitterHandle} hashtags={hashtags}>
                <TwitterIcon  size={SIZE} round={ROUND} />
          </TwitterShareButton>

          <LinkedinShareButton url={siteUrl} >
            <LinkedinIcon  size={SIZE} round={ROUND}/>
          </LinkedinShareButton>

          <RedditShareButton url={siteUrl} title={socialTitle} >
            <RedditIcon  size={SIZE} round={ROUND} />
          </RedditShareButton>

          <WhatsappShareButton url={siteUrl} title={socialTitle}>
               <WhatsappIcon  size={SIZE} round={ROUND}/>
           </WhatsappShareButton>
           <div className="coffee">
           <a href="https://www.buymeacoffee.com/stimsims" target="_blank">
               <img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee"  />
            </a>
           </div>
           {/* <script type="text/javascript" src="https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js" data-name="bmc-button" data-slug="stimsims" data-color="#FFDD00" data-emoji="" data-font="Cookie" data-text="Buy me a coffee" data-outline-color="#000000" data-font-color="#000000" data-coffee-color="#ffffff" ></script> */}
    </div>;
}

ShareView.propTypes = {
  siteInformation: PropTypes.object.isRequired
};

ShareView.defaultProps = {
};


export default ShareView;