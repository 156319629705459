import React from 'react';
import styled from 'styled-components';
import {log} from './../../utilities/measure.js';

const ButtonView = ({children, id,  onInput, bgCol, borderCol, textCol, roundedCorners}) => {
    return <Button id={id} onClick={onInput} borderCol={borderCol} bgCol={bgCol}>
        {children}
    </Button>
}

export default ButtonView;

const Button = styled.button`
    background: ${props => {
        return props.theme.primary;
    }};
    border: 3px solid ${props => {
        return props.theme.primaryL;
    }};
    color: white;
    border-radius: 5px;
    margin: 5px;
    padding:2px 10px;
    outline: 0;
    transition: opacity ${props => props.theme.animS} ease, transform ${props => props.theme.animS} ease;
    opacity: 0.7;
    &:hover{
        opacity:1;
    }
`