import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Loading from '../Loading'
import Loadable from 'react-loadable';
import Helmet from 'react-helmet'
import {log} from './../../utilities/measure.js';

const LoadableComponent = Loadable({
    loader: () => import('./Katex.js'),
    loading: (props)=>{
        if (props.error) {
            return <p>error</p>
        } else {
            return <p>loading</p>
        }
      },
    //   render(Katex, props) {
    //     log(`Load katex`, Katex);
    //     log(`Load  props`, props);
    //     return Katex;
    // }
  });

const Math = (props) => {
    return <>
        <Helmet>
            <link rel="stylesheet"  href="https://cdn.jsdelivr.net/npm/katex@0.10.2/dist/katex.min.css" async defer/>
        </Helmet>
        <LoadableComponent latex={props.latex} block={props.block} id={props.id} />
    </>
}

export default Math;

// const Load = () => {
//     return Loadable.Map({
//       timeout: 10000,
//       delay: 0,
//       loader: {
//       //  katexCss: ()=>{return import('https://cdn.jsdelivr.net/npm/katex@0.10.2/dist/katex.min.css')},
//         katex: ()=>{return import('./Katex.js')},
//        // GameTool: () => import('game/Main.js'),
//       },
//       loading: (props)=>{
//         if (props.error) {
//             return <p>error</p>
//         } else {
//             return <p>loading</p>
//         }
        
//       },
//       render(loaded, props) {
//           log(`Load loaded ${loaded} props`, props);
//           return <props.katex
//             latex={`a+b`}
//           />
//       }
//     });
//   }
  