import {log} from './../../utilities/measure.js';
import {roundTo} from './display';
import {DP} from './keys.js';

export const DRUGS = ['morphine', 'aspirin', 'paracetamol', 'diazepam', 'penicillin', 'buscopon', 'pethidine']
export const DRUGS_TOXIC = DRUGS;//['phoenix down', 'hunters formula']
export const DELIVERY_FORMS = ['pill', 'injection', 'drip', 'toxic'];
export const DENSITIES = [50, 100, 150, 300, 500];
export const MILLILITERS = [1, 2, 5, 10];
export const UNIT = ['mg', 'mcg'];
export const DRIP_RATE = [20, 60];
export const PATIENT_NAMES = ['Bill', 'Brandy', "Bettie", 'Belinda', 'Brian', 'Bob', 'Bron', 'Bell', 'Brad', 'Bruce']






const getRandomElement = (ray) => {
    return ray[Math.floor(Math.random()*ray.length)];
}
const convertUnit = (unitFrom, unitTo, value) => {
   
    let r = null
    if(unitFrom === unitTo){
        r = value;
    }else{
        switch(unitFrom){
            case 'mg':
                if(unitTo === 'mcg') r = value*1000;
            case 'mcg':
                if(unitTo === 'mg') r = value/1000;
        }
    }

   // log(`testCalculateAnswer convertUnit unitFrom ${unitFrom} unitTo ${unitTo} value ${value} result ${r}`);
    if(r || r === 0) return r;
    throw Error('type mismatch')
}

export const getAllDrugs = (question) => {
    return question === 'bsa'?DRUGS_TOXIC:DRUGS;
}
export const getDrug = (question) => {
    return getRandomElement(getAllDrugs(question));
}
export const makeStock = (question, drug, isToxic) => {
    switch(question){
        case 'drip': return makeDripStock(drug);
        case 'bsa':
        case 'dose': return makePillStock(drug, isToxic);
        default:
            throw Error(`invalid question type`);
    }
}
const makeDripStock = (drug) => {
    let vector, vectorVolume, vectorVolumeUnit, text;
    vector = 'drip';
    vectorVolume = 1000;
    vectorVolumeUnit = 'ml'
 
    let unit = getRandomElement(UNIT);
    let strength = getRandomElement(DENSITIES);
    text = `${strength} ${unit} of ${drug} per ${vectorVolume} ml`;
 
    return {
        type: 'stock',
        unit,
        strength,
        drug,
        vector,
        question: vector,  
        vectorVolume,
        vectorVolumeUnit,
        text,
        img: 'drip',
        properties: {
            stockStrength: `${strength} ${unit} per ${vectorVolume} ${vectorVolumeUnit}`
        }
    };
}
const makePillStock = (drug = 'morphine', isToxic) => {
    let vector, vectorVolume, text, img;
    if(Math.random()>0.5){
        vector = 'ml';
        vectorVolume = Math.ceil(Math.random()*8);
        img="injection";
       // vectorUnit = getRandomElement(UNIT);
    }else{
        vector = 'pill';
        vectorVolume = 1;
        img = 'pill';
        //vectorUnit = getRandomElement(UNIT);
    }
    if(isToxic){
        img = 'toxic';
    }
   // if(DRUGS_TOXIC.indexOf(drug) > -1) img = 'toxic';
    let unit = getRandomElement(UNIT);
    let strength = getRandomElement(DENSITIES);
    text = `${strength} ${unit} of ${drug} per ${vector === 'pill'?'pill':`${vectorVolume} ${vector}`}`;
  
    return {
        type: 'stock',
        unit,
        strength,
        drug,
        vector, 
        question: 'dose',
        vectorVolume,
        text,
        img,
        properties: {
            stockStrength: `${strength} ${unit} per ${vectorVolume} ${vector}`
        }
    };
}

/**
 * The client needs 30mcg of drug X
 * some are intravenous - need 30mcg delivered in 2 hours
 * 
 * Injection: the client needs 30mcg of morphine
 */

export const makeQuestion = (question, drug) => {
    //drug, form, density,  units
    log(`makeQuestion question ${question} drug ${drug}`);
    let parameters = {question, drug, name: getRandomElement(PATIENT_NAMES)};
    switch(question){
        case 'bsa':
            //allow dose to determine some aspects of question
            parameters.recommendedDoseUnit = getRandomElement(UNIT); 
            parameters.recommendedDose = 2*getRandomElement(DENSITIES);
            parameters.bsa = roundTo(Math.random() + 0.5, 1);
            parameters.text = `${parameters.name} has a body surface area of ${parameters.bsa} m^2. The recommended dosage of ${drug} 
            is ${parameters.recommendedDose} ${parameters.recommendedDoseUnit} per m^2. How much medication should ${parameters.name} be given?`;
            parameters.properties = {
                patientStrength: `${parameters.recommendedDose} ${parameters.recommendedDoseUnit}`,
                bsa: parameters.bsa,
            }
            break;
        case 'dose':
            parameters.recommendedDose = 2*getRandomElement(DENSITIES);
            parameters.recommendedDoseUnit = getRandomElement(UNIT);
            parameters.text =  `${parameters.name} needs ${parameters.recommendedDose} ${parameters.recommendedDoseUnit} of ${drug}. 
            How much medication should ${parameters.name} be given?`;
            parameters.properties = {
                patientStrength: `${parameters.recommendedDose} ${parameters.recommendedDoseUnit}`
            }
            break;
        case 'drip':
            parameters.recommendedDose = 2*getRandomElement(DENSITIES);
            parameters.recommendedDoseUnit = getRandomElement(UNIT);
            parameters.dripRate = getRandomElement(DRIP_RATE); //drip rate per hour
            parameters.dripUnit = 'ml';
            parameters.dripTime = 'hour';
            parameters.text = `${parameters.name} needs ${parameters.recommendedDose} ${parameters.recommendedDoseUnit} of ${drug}. 
            You set the intravenous pump to ${parameters.dripRate} ${parameters.dripUnit} per ${parameters.dripTime}. How long will it take ${parameters.name}
            to receive their medicine?`;
            parameters.properties = {
                patientStrength: `${parameters.recommendedDose} ${parameters.recommendedDoseUnit}`,
                dripRate: `${parameters.dripRate} ${parameters.dripUnit} per ${parameters.dripTime}`
            }
            break;
        case 'bsa':
            break;
    }
    log(`GameMaker makeQuestion drug ${drug} parameters`, parameters);
    return {
        type: 'question',
        question,
        ...parameters
    };
}

export const pluralize = (word, quantity) => {
    if(quantity === 1) return word;
    return word + 's';
}
export const calculateAnswer = (question, stock) => {
    //log(`medication math calculateAnswer question`, question);
    //log(`medication math calculateAnswer stock`, stock);
    let result = {};
    if(!question){
        throw Error(`Patient required to calculate answer`)
    }else if(!stock){
        throw Error(`Stock required to calculate answer`)
    }if(question.drug !== stock.drug){
        throw Error(`Wrong type of drug`)
       // result.error = `Wrong type of drug`;
    }else if((question.question === 'drip' || stock.question === 'drip') && question.question !== stock.question){
        throw Error(`Wrong drug vector. Some questions must be given via intravenous drip, or by pill or injection.`)
    }else if(question && stock && question.question === 'dose'){
        //convert the question unit to the stock unit
        
        let recommendedDoseUnit = question.recommendedDoseUnit;
        let recommendedDose = question.recommendedDose;
        try{
            result.amountRequired = convertUnit(recommendedDoseUnit, stock.unit, recommendedDose);
        }catch(e){
            result.error = `unit mismatch question: ${recommendedDoseUnit}, stock: ${stock.unit}`
        }
        result.dose = result.amountRequired/stock.strength;
        result.quantity = result.dose*stock.vectorVolume;
        result.unit = pluralize(stock.vector, result.quantity);
        result.text = `${question.name} requires ${result.amountRequired} ${stock.unit} of ${stock.drug}, 
        which is ${result.quantity} ${result.unit} of the stock ${stock.drug}`;
        
       // log(`calculateAnswer result`, result);
    }else if(question && stock && question.question === 'bsa'){
        try{
            result.amountRequired = convertUnit(question.recommendedDoseUnit, stock.unit, question.recommendedDose);
        }catch(e){
            result.error = `unit mismatch question: ${question.recommendedDoseUnit}, stock: ${stock.unit}`
        }
        result.bsaAmount = result.amountRequired*question.bsa;
        result.dose = result.bsaAmount/stock.strength;
        result.quantity = result.dose*stock.vectorVolume;
        result.unit = pluralize(stock.vector, result.quantity);
        result.text = `${question.name} requires ${result.bsaAmount} ${question.recommendedDoseUnit} of ${stock.drug}, 
        which is ${result.quantity} ${result.unit} of the stock ${stock.drug}`;
        //log(`calculateAnswer result`, result);
    }else if(question && stock && question.question === 'drip'){
        result.patientRecommendedDoseConverted = convertUnit(question.recommendedDoseUnit, stock.unit, question.recommendedDose);
        result.dose = result.patientRecommendedDoseConverted/stock.strength;
        result.volume = result.dose*stock.vectorVolume;
        result.dripRate = convertUnit(question.dripUnit, stock.vectorVolumeUnit, question.dripRate);
        result.time = result.volume/result.dripRate;
        // log(`calculateAnswer drip result patientRecommendedDoseConverted ${result.patientRecommendedDoseConverted} 
        //             volume ${result.volume} dripRate ${result.dripRate} time ${result.time}`);
        result.quantity = result.time;
        result.unit = pluralize('hour', result.time);
        result.text = `${question.name} requires ${result.patientRecommendedDoseConverted} ${stock.unit} of ${stock.drug}.
        With a stock concentration of ${stock.strength} ${stock.unit} of ${stock.drug} per ${stock.vectorVolume} ${stock.vectorVolumeUnit}, 
        that'll require ${result.volume} ${stock.vectorVolumeUnit} of stock.
        At a drip rate of ${result.dripRate} ${stock.vectorVolumeUnit}, that'll take ${result.time} ${result.unit} to deliver.`;
       
    }else{
        result.error = 'insufficient data';
    }
    //log(`medication math calculateAnswer result`, result);
    return result;
}
// const testCalculateAnswer = () => {
//     let stock = {type:'stock',  unit: 'injection', strength: 150, drug: 'morphine', vector: 'ml', vectorVolume: 1, unit: 'mg'};
//     let question = {type: 'question', question: 'dose', name: 'Denton', drug: 'morphine', recommendedDose: 600, recommendedDoseUnit: 'mg'};
//     let result = calculateAnswer(question, stock);
//     log(`testCalculateAnswer dose stock ${printObject(stock)}, question ${printObject(question)}, answer ${printObject(result)}`);
//     let dripStock = {type: 'stock',  drug: 'morphine', strength: 100, unit: 'mg', vectorVolume: 250, vectorVolumeUnit: 'ml'};
//     let dripQuestion = {type: 'question', question: 'drip',drug: 'morphine', name: 'Denton', recommendedDoseUnit: 'mg', recommendedDose: 200, dripUnit: 'ml', dripRate: 60}
//     let dripResult = calculateAnswer(dripQuestion, dripStock);
//     log(`testCalculateAnswer drip stock ${printObject(dripStock)}, question ${printObject(dripQuestion)}, answer ${printObject(dripResult)}`);
//     let bsaStock = {type:'stock',  unit: 'injection', strength: 150, drug: 'morphine', vector: 'ml', vectorVolume: 1, unit: 'mg'};
//     let bsaQuestion = {type: 'question', question: 'bsa', name: 'Denton', bsa: 0.8, drug: 'morphine', recommendedDose: 600, recommendedDoseUnit: 'mg'};
//     let bsaResult = calculateAnswer(bsaQuestion, bsaStock);
//     log(`testCalculateAnswer bsa stock ${printObject(bsaStock)}, question ${printObject(bsaQuestion)}, 
//     answer ${printObject(bsaResult)}`);
// }
// //testCalculateAnswer();

