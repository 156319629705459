import styled from 'styled-components';


export const Placeholder = styled.div`
    width: ${props => props.width?props.width:'100%'};
    height: ${props => props.height?props.height:'140px'};
    ${props => props.color?`background-color: ${props.color};`:''};
    position: relative;
`

export const Spinner = styled.div`
    position: absolute;
    height:  300px; width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    .spinner-text{
        color: white;
        position: absolute;
        top: 90%;
        left: 50%;
        transform: translateX(-50%);
        animation: textFlash 2s ease alternate infinite;
        white-space: nowrap;
    }
    .orb{
        height:5%;
        width:5%;
        border-radius: 50%;
        background-color: hotpink;
        position: absolute;
        transform: translate(-50%, -50%);
        animation: spin 2.4s linear infinite;
        opacity: 0.1;
    }
    .orb:nth-child(1){
        top: 20%;
        left: 50%;
        animation-delay: 0s;
    }
    .orb:nth-child(2){
        top: 28.79%;
        left: 71.21%;
        animation-delay: 0.3s;
    }
    .orb:nth-child(3){
        top: 50%;
        left: 80%;
        animation-delay: 0.6s;
    }
    .orb:nth-child(4){
        top: 71.21%;
        left: 71.21%;
        animation-delay: 0.9s;
    }
    .orb:nth-child(5){
        top: 80%;
        left: 50%;
        animation-delay: 1.2s;
    }
    .orb:nth-child(6){
        top: 71.21%;
        left: 28.79%;
        animation-delay: 1.5s;
    }
    .orb:nth-child(7){
        top: 50%;
        left: 20%;
        animation-delay: 1.8s;
    }
    .orb:nth-child(8){
        top: 28.79%;
        left: 28.79%;
        animation-delay: 2.1s;
    }
    @keyframes textFlash{
        from{opacity: 0;}
        to{opacity: 1;}
    }
    @keyframes spin{
        5%{opacity: 0.1; transform: translate(-50%, -50%) scale(1);}
        10%{opacity: 1; transform: translate(-50%, -50%) scale(4);}
        70%{opacity: 0.1; transform: translate(-50%, -50%) scale(1);}
    }
`


export const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    padding: 0;
    width: 100%;
    ${props => props.height?'height: ' +props.height + ';':''}
    text-align: center;
    .error{
        padding: 0; margin: 0;
        p{margin: 0; padding: 0;}
        color: ${props => props.error?props.theme.graph2:props.theme.primary};
    }
    .box-text{
        position: relative;
        top: -15px;
        display: block;
    }
`

export const Box = styled.div`
    display: inline-block;
    width: ${props => props.length?props.length:'30px'};
    height: ${props => props.length?props.length:'30px'};
    margin: ${props => props.space?props.space:'30px'};
    background-color: ${props => props.error?props.theme.graph2:props.theme.primary};
    padding: 0;
    opacity: 0.2;
    animation: pulse 2s ease-in 0.3s infinite normal;
    @keyframes pulse {
        0% {opacity: 0.2; transform: scale(1, 1);}
        25% {opacity: 1; transform: scale(1.5, 1.5);}
        50% {opacity: 1; transform: scale(1.5, 1.5);}
        76% {opacity: 0.2; transform: scale(1, 1);}
    }
    &.loading-box:nth-child(2).loading-box{
        animation-delay: 0.6s;
    }
    &.loading-box:nth-child(3){
        animation-delay: 0.9s;
    }
`

